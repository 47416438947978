<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addProspectNote"
        ref="addProspectNote"
        v-model="isFormValid"
        class="custom-form pt-6"
        @submit.prevent="submitNewDepartmentForm"
      >
        <v-col cols="12">
          <DateWithTimePicker
            :date-with-time="callStart"
            label="Czas rozmowy"
            @changeTime="callStart = $event"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="notes"
            outlined
            :rows="3"
            auto-grow
            :rules="[rules.required]"
            label="Notatki"
            hide-details
            placeholder="Wpisz notatki do rozmowy"
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addProspectNote"
      >
        Dodaj notatkę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import DateWithTimePicker from './../Forms/Inputs/DateWithTime'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    DateWithTimePicker
  },
  data () {
    return {
      rules,
      notes: null,
      isFormValid: true,
      callStart: new Date().toISOString()
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.prospect.isProcessing,
      prospectId: state => state.prospect.entity.id
    })
  },
  methods: {
    ...mapActions({
      addProspectNote: 'prospect/addProspectNote',
      closeDialog: 'layout/closeDialog'
    }),
    submitNewDepartmentForm () {
      this.$refs.addProspectNote.validate()
      if (this.isFormValid) {
        const params = {
          text: this.notes,
          callStart: this.callStart,
          prospectId: this.prospectId,
        }
        this.addProspectNote(params)
          .then(() => {
            this.closeDialog()
          })
      }
    }
  }
}
</script>
